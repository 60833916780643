@import "https://rsms.me/inter/inter.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* width */
::-webkit-scrollbar {
  @apply w-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-ngray-700;
  @apply rounded;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-ngray-900;
  @apply rounded;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-ngray-600;
}

.content-image-row-swiper {
  width: 100%;
  height: 100%;
  overflow: visible !important;

  .swiper-pagination {
    --swiper-pagination-color: rgba(248, 250, 252, 1);
    --swiper-pagination-bullet-inactive-color: rgba(248, 250, 252, 1);
    --swiper-pagination-bullet-inactive-opacity: 0.5;
    text-align: right;
    @apply pr-5;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(.swiper-slide-visible) {
      div {
        a {
          div {
            @apply hover:border-0 transform-none hover:scale-100 transition-none;
          }
        }
      }
      @apply opacity-20;
    }
  }

  .swiper-slide img,
  .swiper-slide div {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: rgb(248 250 252) !important;
  }

  &.is-mobile {
    .swiper-button-prev {
      display: none;
    }

    .swiper-button-next {
      display: none;
    }
  }
}

body {
  @apply text-ngray-50;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

a {
  background-color: transparent;
}

div {
  display: block;
}

* {
  box-sizing: border-box;
}

img {
  border-style: none;
}

a p,
span p {
  font-size: 13px;
  letter-spacing: 1.42px;
  line-height: 1.08;
  margin-left: 8px;
  padding: 2px 0px;
  white-space: nowrap;
}

a,
span {
  svg {
    @apply text-ngray-50;
    height: 24px;
    min-width: 24px;
    width: 24px;
    z-index: auto;
  }
}

/** add styles to prevent CLS (Cumulative Layout Shift) */
media-controller:not([audio]) {
  display: block; /* expands the container if preload=none */
  aspect-ratio: 16 / 9; /* set container aspect ratio if preload=none */
}

video {
  max-height: inherit;
  max-width: inherit;
  height: 100%;
  width: 100%; /* prevents video to expand beyond its container */
}

.prose {
  a {
    @apply text-ngray-50 hover:text-ngray-300;
  }
}

.faq-answer,
.content-detail-description {
  @apply text-lg text-ngray-200;

  p {
    @apply pb-2;
  }

  a {
    @apply underline hover:text-ngray-300;
  }
}
